/* eslint-disable no-nested-ternary */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import APIM from 'Services/APIM';
import Auth0SessionInfo from 'Services/Auth0SessionInfo';
import CDATA from 'Services/CDATA';
import { TDView, TDBody } from './TransactionDashboard.styles';
import AppContext from 'AppContext';
import GrpToolbar from 'Library/GrpToolbar/GrpToolbar';
import GrpGrid from 'Library/GrpGrid/GrpGrid';
import GrpSpinner from 'Components/GrpSpinner/GrpSpinner';
import GrpError from 'Components/GrpError/GrpError';
import GrpButton from 'Library/GrpButton/GrpButton';
import GrpSelect from 'Library/GrpSelect/GrpSelect';
import DateModalContent from './DateModalContent';
import MarketDataModalContent from './MarketDataModal/MarketDataModalContent';
import NavLinkButton from 'Library/GrpGrid/NavLinkButton/NavLinkButton';
import { renderWithPermissions, userHasPermission } from 'Components/Auth0/permissionFunctions';
import { permissionTypes } from 'Constants/Auth0Constants';
import UrlPoller from 'Library/UrlPoller/UrlPoller';
import 'Styles/SCSS/ag-theme-material.scss';


const PreviewButton = () => 'Show Preview';

const ValidationErrors = (data) => (
  data && (data.validationerrorsexist === true || data.validationerrorsexist === 'true') ? 'See Errors' : 'No Errors'
);

const linkData = (data, path) => ({
  pathname: path,
  fundcode_bk: data.fundcode_bk,
  fund_name: data.fund_name,
  action_type: 'Edit',
  regulation_name: window.location.pathname === '/transactions/dashboard/dcpt' ? 'DCPT'
    : window.location.pathname === '/transactions/dashboard/cti' ? 'CTI'
      : 'PRIIPS/MIFID II',
});

const PreviewButtonLink = (data) => (data ? linkData(data, '/transactions/preview/all-transactions') : null);

const ValidationErrorsLink = (data) => data && linkData(data, '/transactions/dashboard/validationerrors');

let permissionPrefix = '';

class TransactionDashboard extends Component {
  constructor(props) {
    super(props);
    permissionPrefix = props.location.pathname.toLowerCase().endsWith('dashboard') ? 'priips/' : '';

    this.state = {
      api_name: this.props.api_name,
      // path: this.props.location.pathname,
      transactionType:
        this.props.location.pathname === '/transactions/dashboard/dcpt' ? 'DCPT'
          : this.props.location.pathname === '/transactions/dashboard/cti' ? 'CTI'
            : 'PRIIPS/MIFID II',
      api_approval:
        this.props.location.pathname === '/transactions/dashboard/dcpt' ? 'dcpt'
          : this.props.location.pathname === '/transactions/dashboard/cti' ? 'cti'
            : 'priips',
      displayedRowCount: 0,
      error_or_warning: 'Error',
      failed: false,
      loading: true,
      permissions: this.props.permissions,
      totalRowCount: 0,
      toolbarButtons: [
        renderWithPermissions(() => (
          <GrpSelect
            disabled={this.state.disableRollUpTc === true}
            items={[
              {
                display: (
                  <GrpButton
                    key={0}
                    onClick={() => { this.process(3); }}
                    size="Medium"
                    type="Tertiary"
                    text="Calculate & Rollup Underlying TCs"
                  />
                ),
                value: 0,
              },
              {
                display: (
                  <GrpButton
                    key={1}
                    onClick={() => { this.process(1); }}
                    size="Medium"
                    type="Tertiary"
                    text="Rollup All Selected Funds"
                  />
                ),
                value: 1,
              },
              {
                display: (
                  <GrpButton
                    key={2}
                    onClick={() => { this.process(2); }}
                    size="Medium"
                    type="Tertiary"
                    text="Rollup Only Uncalculated Funds"
                  />
                ),
                value: 2,
              },
            ]}
            placeholder={{
              display: (
                <>TC Operations&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
              ),
              value: null,
            }}
            alwaysDefault
          />
        ), (permissionPrefix + permissionTypes.calculations), () => <></>),
        renderWithPermissions(() => (
          <GrpButton
            key={0}
            // disabled={this.state.totalRowCount === 0}
            onClick={() => { this.process(5); }}
            size="Medium"
            type="Primary"
            text="Run BR"
          />
        ), (permissionPrefix + permissionTypes.validate), () => <></>),
        <GrpSelect
          items={[
            {
              display: (
                <GrpButton
                  key={1}
                  onClick={() => { this.createTransactionResultsExtract(); }}
                  size="Medium"
                  type="Tertiary"
                  text="Transaction Results"
                />
              ),
              value: 1,
            },
            {
              display: (
                <GrpButton
                  key={2}
                  onClick={() => { this.createPerMonthExtract(); }}
                  size="Medium"
                  type="Tertiary"
                  text="Per Month"
                />
              ),
              value: 2,
            },
            {
              display: (
                <GrpButton
                  key={3}
                  onClick={() => { this.createDatePrompt(); }}
                  size="Medium"
                  type="Tertiary"
                  text="All Transactions"
                />
              ),
              value: 3,
            },
            {
              display: (
                <GrpButton
                  key={4}
                  onClick={() => { this.createExtract(); }}
                  size="Medium"
                  type="Tertiary"
                  text="All Errors"
                />
              ),
              value: 4,
            },
          ]}
          placeholder={{ display: 'Extract Data', value: null }}
          alwaysDefault
        />,
        renderWithPermissions(() => (
          <GrpButton
            key={0}
            // disabled={this.state.totalRowCount === 0}
            onClick={() => { this.process(6); }}
            size="Medium"
            type="Primary"
            text="Send Funds for Market Data (with Date)"
          />
        ), (permissionPrefix + permissionTypes.send), () => <></>),
      ],
      monitorUrl: '',
      monitorType: '',
    };
  }

  componentDidMount() {
    this.getMetadata();
  }

  onPollingDone() {
    if(this.state.monitorType.includes('Bulk Transaction Cost Calculation')) {
      this.refreshData();
    }
    if(this.state.monitorType.includes('Individual Transaction Cost Calculation')){
      this.refreshData();
      if (this.state.monitorType.includes('DCPT')){
        this.bulkTCAAUpdate(false);
      }else{
        this.bulkTCUpdate(false);
      }      
      this.setState({disableRollUpTc: false, calculatedListofRows: null});

    }

    this.setState({ monitorUrl: undefined });
  }

  onSecondPollingDone() {
    this.setState({ secondMonitorUrl: undefined });
  }

  async getMetadata() {
    try {
      const columns = await CDATA.makeRequest(
        'POST',
        'PRIIPS_app_GET_COLUMNS',
        '',
        {
          API_NAME: this.state.api_name,
          MERGE_QUEUE_ID: -99,
        },
        'Error retrieving column names and metadata.',
      );
      const columnDefs = this.mapColumns(columns.value);
        
      if (this.state.api_name === 'PRIIPS_app_TRANSACTION_DASHBOARD') {
        if (userHasPermission(this.state.permissions, permissionPrefix + permissionTypes.preview)) {
          columnDefs.unshift({
            field: 'Preview',
            headerName: 'Transaction Cost Calculation',
            cellRendererFramework: (props) => (
              <NavLinkButton
                {...props}
                functionToGenerateContent={PreviewButton}
                functionToGenerateLink={PreviewButtonLink}
              />
            ),
            sortable: true,
            cellClass: 'ag-cell',
            valueGetter: (props) => PreviewButton(props.data),
            enablePivot: false,
            enableRowGroup: false,
            enableValue: false,
          });
        }

        columnDefs.unshift({
          field: 'Validation Errors',
          headerName: 'Validation Errors',
          cellRendererFramework: (props) => (
            (props.data.validationerrorsexist === true || props.data.validationerrorsexist === 'true')
              ? (
                <NavLinkButton
                  {...props}
                  functionToGenerateContent={ValidationErrors}
                  functionToGenerateLink={ValidationErrorsLink}
                />
              )
              : <></>
          ),
          sortable: true,
          cellClass: 'ag-cell',
          valueGetter: (props) => ValidationErrors(props.data),
          enablePivot: false,
          enableRowGroup: false,
          enableValue: false,
        });
      }

      columnDefs.unshift({
        field: 'SelectRow',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        maxWidth: 45,
        resizable: false,
      });

      this.setState({
        columnDefs,
        rawColumns: columns.value,
      });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading the columns for this table. Please try again. If the issue persists, contact your administrator.',
      });
      console.log(err);
    }
  }

  async getData() {
    try {
      let requestType = 'POST';
      const payload = {
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
        Type: this.state.transactionType,
      };
      let urlParams = '';
      if (this.state.api_name === 'PRIIPS_app_Transaction_Validation_Summary') {
        payload.ClientCode = this.context.state.selectedClient.code;
      }
      if (this.state.api_name === 'PRIIPS_logging_transaction_validation_error') {
        // payload.Error_Level = this.state.error_or_warning;
        requestType = 'GET';
        urlParams = CDATA.addFilter(`Error_Level eq '${this.state.error_or_warning}' and ClientCode_bk eq '${this.context.state.selectedClient.code}'`);
        // payload.entity = `Bulk ${this.state.error_or_warning} Logging`;
      }
      const data = await CDATA.makeRequest(
        requestType,
        this.state.api_name,
        urlParams,
        payload,
        'Error retrieving data.',
      );
      const totalRowCount = data && data['@odata.count'] !== 0 ? data['@odata.count'] : data.value.filter((item) => item.return_value !== '0').length;
      this.gridApi.setRowData(data.value.filter((item) => item.return_value !== '0'));
      this.gridApi.onFilterChanged();

      this.setState({ loading: false, totalRowCount });
    } catch (err) {
      this.setState({
        loading: false,
        failed: true,
        failMessage: 'We\'re sorry, there was an issue loading this data. Please try again. If the issue persists, contact your administrator.',
      });
    }
  }

  mapColumns = (rawColumns) => rawColumns.map((x) => ({
    field: x.dv_column,
    headerName: x.display_name,
    hide: x.is_hidden,
    editable: x.editable,
    comparator: x.sorttype !== undefined && x.sorttype.toLowerCase() === 'numeric' && ((value1, value2) => (value1 - value2)),
  }));

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    await this.getData();
  };

  onModelUpdated = (params) => {
    // console.log('ag grid model updated', params);
    this.setState({ displayedRowCount: params.api.getDisplayedRowCount() });
  }

  /**
   * Handles the onClick behavior of the many buttons on this page.
   * @param {*} update 
   * @returns 
   */
  process = (update) => {
    const listOfRows = this.gridApi.getSelectedRows();
    if (this.state.totalRowCount === 0) {
      const toast = {
        type: 'error',
        body: (<>No data available to process.</>),
      };
      this.context.handlers.setToast(toast);
      return;
    } else if (listOfRows.length > 0) {
      const modalContent = (
          <div>
              <p>
                  {`Are you sure you want to process the selected ${listOfRows.length === 1 ? 'row' : `${listOfRows.length} rows`}?`}
              </p>
          <GrpButton
            onClick={() => (
              update === 1 && this.state.api_approval === 'priips' ? this.bulkTCUpdate(false) : (
                update === 2 && this.state.api_approval === 'priips' ? this.bulkTCUpdate(true) : (
                  update === 3 ? this.bulkTransactionUpdate() : (
                    update === 1 && this.state.api_approval === 'dcpt' ? this.bulkTCAAUpdate(false) : (
                      update === 2 && this.state.api_approval === 'dcpt' ? this.bulkTCAAUpdate(true) : (
                        update === 1 && this.state.api_approval === 'cti' ? this.bulkCTIUpdate(false) : (
                          update === 2 && this.state.api_approval === 'cti' ? this.bulkCTIUpdate(true) : (
                            update === 5 ? this.validateTransactions() : (
                              update === 6 ? this.createMarketDataModal() : null
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )}
            size="Medium"
            type="Primary"
            text=" Ok "
          />
          <GrpButton
            onClick={() => this.context.handlers.toggleModal()}
            size="Medium"
            type="Primary"
            text="Cancel"
          />
        </div>
      );
      this.context.handlers.setModal('', modalContent, false);
      this.context.handlers.toggleModal();
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to process.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  /**
   * Bulk Transaction Cost Update
   *
   * @param {*} onlyNew Boolean describing whether or not only new TCS are rolled up
   */
  bulkTCUpdate = (onlyNew) => {
    let listOfRows = this.state.calculatedListofRows;
    if (this.state.calculatedListofRows == null){
      listOfRows = this.gridApi.getSelectedRows();
    };
    const listOfFunds = [];
    let delimFunds = '';
    let modalContent = <div><p>{`Processing ${listOfRows.length} row${listOfRows.length === 1 ? '' : 's'}...`}</p></div>;
    this.context.handlers.setModal('Processing ...', modalContent, null, null,  true);
    listOfRows.forEach((row) => {
      listOfFunds.push(row.fundcode_bk);
    });
    delimFunds = listOfFunds.join(',');

    const data = {
        procedure: 'app.Transaction_Costs_Per_Period_Year_3Years_Update,app.Transaction_Costs_Per_Period_Year_3Years_Update_v2',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        Fund_Codes: delimFunds,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
        Entity: `Bulk Transaction Cost Calculation for ${this.state.transactionType}`,
        Class_Code: '',
        OnlyNew: onlyNew
      },
    };

    APIM.makeRequest(
      'transaction',
      JSON.stringify(data),
      'Approve failed.',
      Auth0SessionInfo.GetSessionInfo().accessToken
    )
      .then((res) => { // res
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You queued '}
              <span>{listOfRows.length}</span>
              {' row '}
              {listOfRows.length > 1 ? 's' : ''}
              {' for PRIIPS Bulk Transaction Cost Calculation. Please wait...'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'Bulk Transaction Cost Calculation' });
      })
      .catch(() => { // error
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  /**
   * 
   * @param {*} onlyNew 
   */
  bulkTCAAUpdate = (onlyNew) => {
    
    let listOfRows = this.state.calculatedListofRows;
    if (this.state.calculatedListofRows == null){
      listOfRows = this.gridApi.getSelectedRows();
    };
    const listOfFunds = [];
    let delimFunds = '';
    
    const modalContent = <div><p>{`Processing ${listOfRows.length} row${listOfRows.length === 1 ? '' : 's'}...`}</p></div>;
    this.context.handlers.setModal('Processing ...', modalContent, null, null,  true);
    listOfRows.forEach((row) => {
      listOfFunds.push(row.fundcode_bk);
    });
    delimFunds = listOfFunds.join(',');
    
    let data = {
        procedure: 'app.Transaction_Costs_DCPT_Actuals_Annualized_Update,app.Transaction_Costs_DCPT_Actuals_Annualized_Update_v2',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        Fund_Codes: delimFunds,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
        Entity: `Bulk Transaction Cost Calculation Actuals Annualized Update for ${this.state.transactionType}`,
        Class_Code: '',
        OnlyNew: onlyNew,
      },
    };
    APIM.makeRequest(
      'transaction',
      JSON.stringify(data),
      'Approve failed..',
      Auth0SessionInfo.GetSessionInfo().accessToken
    )
      .then((res) => { // res
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You queued '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' for DCPT Bulk Transaction Cost Calculation. Please wait...'}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'DCPT Bulk Transaction Cost Calculation' });
      })
      .catch(() => { // error
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });

    data = {
        procedure: 'app.Transaction_Costs_DCPT_Annualized_Update,app.Transaction_Costs_DCPT_Annualized_Update_v2',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        Fund_Codes: delimFunds,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
        Entity: `Bulk Transaction Cost Calculation Annualized Update for ${this.state.transactionType}`,
        Class_Code: '',
        OnlyNew: onlyNew,
      },
    };
    APIM.makeRequest('transaction', JSON.stringify(data), 'Approve failed.', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then((res) => { // res
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You queued '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' for DCPT Bulk Transaction Cost Calculation. Please wait...'}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({
          secondMonitorUrl: res.statusQueryGetUri,
          secondMonitorType: data.parameters.Entity
        });
      })
      .catch(() => { // error
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  /**
   * 
   * @param {*} onlyNew 
   */
  bulkCTIUpdate = (onlyNew) => {
    const listOfRows = this.gridApi.getSelectedRows();
    const listOfFunds = [];
    let delimFunds = '';
    const modalContent = <div><p>{`Processing ${listOfRows.length} row${listOfRows.length === 1 ? '' : 's'}...`}</p></div>;
    this.context.handlers.setModal('Processing ...', modalContent, null, null,  true);
    listOfRows.forEach((row) => {
      listOfFunds.push(row.fundcode_bk);
    });
    delimFunds = listOfFunds.join(',');

    const data = {
      procedure: 'app.Transaction_Costs_CTI_AssetClass_Update',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        Fund_Codes: delimFunds,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
        Entity: `Bulk Transaction Cost Calculation for ${this.state.transactionType}`,
        Class_Code: '',
        OnlyNew: onlyNew,
      },
    };
    APIM.makeRequest(
      'transaction',
      JSON.stringify(data),
      'Approve failed.',
      Auth0SessionInfo.GetSessionInfo().accessToken,
    )
      .then((res) => { // res
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You queued '}
              <span>{listOfRows.length}</span>
              {' row '}
              {listOfRows.length > 1 ? 's' : ''}
              {' for CTI Bulk Transaction Cost Calculation. Please wait...'}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({
          monitorUrl: res.statusQueryGetUri,
          monitorType: `CTI Bulk Transaction Cost Calculation`,
        });
      })
      .catch((res) => { // error
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: `Bulk Transaction Cost Calculation for ${this.state.transactionType}` });
      });
  }

  /**
   * Calculate Selected TCs
   */
  bulkTransactionUpdate = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    this.setState({calculatedListofRows: listOfRows });
    const listOfFunds = [];
    let delimFunds = '';
    const modalContent = (
      <div>
        <p>{`Processing ${listOfRows.length} row${listOfRows.length === 1 ? '' : 's'}...`}</p>
      </div>
    );
    this.context.handlers.setModal('Processing ...', modalContent, null, null,  true);
    listOfRows.forEach((row) => {
      listOfFunds.push(row.fundcode_bk);
    });
    delimFunds = listOfFunds.join(',');

    const data = {
        procedure: 'app.Transaction_Costs_Per_Transaction_Update,app.Transaction_Costs_Per_Transaction_Update_v2',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        Fund_Codes: delimFunds,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code,
        Entity: 'Individual Transaction Cost Calculation',
        Class_Code: '',
        OnlyNew: false
      },
    };

    APIM.makeRequest(
      'transaction',
      JSON.stringify(data),
      'Approve failed.',
      Auth0SessionInfo.GetSessionInfo().accessToken,
    )
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You queued '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' for Individual Transaction Cost Calculation. Please wait...'}
              
            </>
            
          ),
        };
        this.context.handlers.setToast(toast);
        var self = this;
        this.setState({
          monitorUrl: res.statusQueryGetUri,
          monitorType: self.state.api_approval === 'dcpt' ? 'Individual Transaction Cost Calculation DCPT' : 'Individual Transaction Cost Calculation',
          disableRollUpTc: true,
        });
        this.refreshData();
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              {'Error - something went wrong and we were not able to run the '}
              <span>{listOfRows.length}</span>
              {' row'}
              {listOfRows.length > 1 ? 's' : ''}
              {' you had selected.'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      })
  }

  createTransactionResultsExtract = async () => {
    if (this.state.totalRowCount === 0) {
      const toast = {
        type: 'error',
        body: (<>No data available to extract.</>),
      };
      this.context.handlers.setToast(toast);
      return;
    }
    const data = {
      procedure: 'Transaction_Costs_Output_Extract',
      ClientCode: this.context.state.selectedClient.code,
      Reference_Date: this.context.state.selectedReportingDate.code,
      FileType: 'Excel',
      BaseFileName: 'Transaction_Results',
      parameters: {
        Client_Code: this.context.state.selectedClient.code,
        CalcDate: this.context.state.selectedReportingDate.code
      },
      ColumnFilters: {
        "TC Results 3 Years":               "EXTRACT_TCR_Per 3 Year",
        "TC Results Per Year":              "EXTRACT_TCR_Per Year",
        "TC Results Per Period":            "EXTRACT_TCR_Per Period",
        "TC Results DCPT":                  "EXTRACT_TCR_DCPT",
        "TC Results Actuals Annualized":    "EXTRACT_TCR_Actuals Annualized",
        "Lookthrough":                      "EXTRACT_TCR_Lookthrough",
        "Lookthrough DCPT":                 "EXTRACT_TCR_Lookthrough DCPT"    
      }
    };

    APIM.makeRequest('generateReport', JSON.stringify(data), 'Error Generating Transaction Results Extract', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {`You have queued a request to extract Transaction Results. It will appear on the Data Management > Exports page when the extract has completed.`}.
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'Transaction Results Extract Generation' });
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              Error - something went wrong and we were not able to generate the
              extract you requested.
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  createPerMonthExtract = async () => {
    if (this.state.totalRowCount === 0) {
      const toast = {
        type: 'error',
        body: (<>No data available to extract.</>),
      };
      this.context.handlers.setToast(toast);
      return;
    }
    const listOfRows = this.gridApi.getSelectedRows();
    if (listOfRows.length > 0) {
      const delimFundCodes = listOfRows.map((row) => row.fundcode_bk).join(',');
      const data = {
        procedure: 'Transactions_Per_Month_Extract',
        clientcode: this.context.state.selectedClient.code,
        parameters: {
          Client_Code: this.context.state.selectedClient.code,
          Fund_Codes: delimFundCodes,
          CalcDate: this.context.state.selectedReportingDate.code,
          Type: this.state.transactionType,
        },
      };

      APIM.makeRequest('generateExtract', JSON.stringify(data), 'Error Per Month Transaction Extract', Auth0SessionInfo.GetSessionInfo().accessToken)
        .then((res) => {
          this.context.handlers.closeModal();
          const toast = {
            type: 'success',
            body: (
              <>
                {'You have successfully submitted data extract request. '}
                {'Please check for the results in a few minutes'}
              </>
            ),
          };
          this.context.handlers.setToast(toast);
          this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'Per Month Transaction Extract Generation' });
        })
        .catch(() => {
          this.context.handlers.closeModal();
          const toast = {
            type: 'error',
            body: (
              <>
                Error - something went wrong and we were not able to generate the
                extract you requested.
              </>
            ),
          };
          this.context.handlers.setToast(toast);
        });
    } else {
      const toast = {
        type: 'error',
        body: (<>No rows were selected to process.</>),
      };
      this.context.handlers.setToast(toast);
    }
  }

  createExtract = async () => {
    if (this.state.totalRowCount === 0) {
      const toast = {
        type: 'error',
        body: (<>No data available to extract.</>),
      };
      this.context.handlers.setToast(toast);
      return;
    }
    const data = {
      procedure: 'Global_Errors_Extract',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        ClientCode: this.context.state.selectedClient.code,
      },
    };

    APIM.makeRequest('generateExtract', JSON.stringify(data), 'Error Generating Global Transaction Extract', Auth0SessionInfo.GetSessionInfo().accessToken)
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              {'You have successfully submitted data extract request. '}
              {'Please check for the results in a few minutes'}
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'Global Transaction Extract Generation' });
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              Error - something went wrong and we were not able to generate the
              extract you requested.
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  handleSelect = (selectedValue) => {
    this.setState({
      error_or_warning: selectedValue.label === 'All Warnings' ? 'Warning' : 'Error',
      api_name: selectedValue.value,
      selectedValue: selectedValue.value,
      loading: true,
    }, () => { this.refresh(); });
  }

  validateTransactions = () => {
    const listOfRows = this.gridApi.getSelectedRows();
    const listOfFunds = [];
    let delimFunds = '';
    const modalContent = (
      <div>
        <p>{`Processing ${listOfRows.length} row${listOfRows.length === 1 ? '' : 's'}...`}</p>
      </div>
    );
    this.context.handlers.setModal('Processing ...', modalContent, null, null,  true);
    listOfRows.forEach((row) => { listOfFunds.push(row.fundcode_bk); });
    delimFunds = listOfFunds.join(',');

    const data = {
      procedure: 'app.Transaction_Validation',
      clientcode: this.context.state.selectedClient.code,
      parameters: {
        Client_Code: this.context.state.selectedClient.code,
        Fund_Codes: delimFunds,
        CalcDate: this.context.state.selectedReportingDate.code,
        USER: this.context.state.userEmail,
        AzureID: this.context.state.userAzureID,
        Entity: 'Validate Transactions',
      },
    };
    APIM.makeRequest(
      'transaction',
      JSON.stringify(data),
      'Validation failed.',
      Auth0SessionInfo.GetSessionInfo().accessToken
    )
      .then((res) => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'success',
          body: (
            <>
              You successfully validated the funds on the selected row
              {listOfRows.length === 1 ? '' : 's'}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
        this.setState({ monitorUrl: res.statusQueryGetUri, monitorType: 'Validate Transactions' });
        this.refreshData();
      })
      .catch(() => {
        this.context.handlers.closeModal();
        const toast = {
          type: 'error',
          body: (
            <>
              Error - something went wrong and we were not able to process the selected row
              {listOfRows.length === 1 ? '' : 's'}
              .
            </>
          ),
        };
        this.context.handlers.setToast(toast);
      });
  }

  createDatePrompt() {
    if (this.state.totalRowCount === 0) {
      const toast = {
        type: 'error',
        body: (<>No data available to extract.</>),
      };
      this.context.handlers.setToast(toast);
      return;
    }
    this.context.handlers.setModal(
      'Select Dates for Extract',
      <DateModalContent />,
      false,
      false,
    );
    this.context.handlers.toggleModal();
  }

  createMarketDataModal() {
    this.context.handlers.setModal(
      'Select Transaction Date Range for Pricing Request',
      <MarketDataModalContent rows={this.gridApi.getSelectedRows()} />,
      false,
    );
    this.context.handlers.openModal();
  }

  refreshData() {
    this.setState({ loading: true }, () => this.getData());
  }

  async refresh() {
    await this.setState({ loading: true }, () => this.getMetadata());
    await this.getData();
  }

  render() {
    return (
      <TDView>
        <TDBody>
          {
            this.state.failed ? <GrpError text={this.state.failMessage} /> : (
              <>
                {
                  this.state.loading ? <GrpSpinner text="Loading data..." /> : (
                    this.gridApi && (
                      <GrpToolbar
                        api={this.gridApi}
                        working={this.state.toolbarWorking}
                        controls={this.state.toolbarButtons}
                        exportName={this.state.api_name}
                        dropdown={(
                          <Select
                            isSearchable
                            options={[
                              { label: 'Transaction Cost Dashboard', value: 'PRIIPS_app_TRANSACTION_DASHBOARD' },
                              { label: 'Business Rules Dashboard', value: 'PRIIPS_app_Transaction_Validation_Summary' },
                              { label: 'All Errors', value: 'PRIIPS_logging_transaction_validation_error' },
                              { label: 'All Warnings', value: 'PRIIPS_logging_transaction_validation_error' },
                            ]}
                            value={this.state.selectedValue}
                            onChange={this.handleSelect}
                            isOptionSelected={(option, selectedValue) => {return selectedValue.some((i) => i.label == option.label)}}
                          />
                        )}
                        count={{
                          total: this.state.totalRowCount,
                          showing: this.state.displayedRowCount,
                        }}
                      />
                    )
                  )
                }

                <GrpGrid
                  columnDefs={this.state.columnDefs}
                  rawColumnData={this.state.rawColumns}
                  onGridReady={this.onGridReady}
                  apiName={this.state.api_name}
                  pk={this.state.pk}
                  onModelUpdated={this.onModelUpdated}
                  pivotable
                />
              </>
            )
          }
        </TDBody>
        <div>
          {
            this.state.monitorUrl ? (
              <UrlPoller
                completeCallback={this.onPollingDone.bind(this)}
                endStates={['Completed']}
                monitorUrl={this.state.monitorUrl}
                refreshIntervalSeconds={10}
                responseDisplayElementPath="$.customStatus"
                monitorType={this.state.monitorType }
              />
            ) : <></>
          }
        </div>

        <div>
          {
            this.state.secondMonitorUrl ? (
              <UrlPoller
                monitorUrl={this.state.secondMonitorUrl}
                refreshIntervalSeconds={10}
                monitorType={this.state.secondMonitorType }
                responseDisplayElementPath="$.customStatus"
                endStates={['Completed']}
                completeCallback={this.onSecondPollingDone.bind(this)}
              />
            ) : <></>
          }
        </div>
      </TDView>
    );
  }
}

TransactionDashboard.propTypes = {
  api_name: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

TransactionDashboard.contextType = AppContext;

export default TransactionDashboard;
